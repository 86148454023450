import React from "react";
import Heading2 from "../../common/text/Heading2";
import Text1 from "../../common/text/Text1";
import Underline, { UnderlinePositionEnum } from "../../common/Underline";
import Container, { ContainerPaddings } from "../../layout/Container";
import ProgramsCard, { ProgramData } from "./ProgramsCards";

export interface ProgramsProps {
  __component: string;
  title: string;
  subtitle: string;
  Program: ProgramData[];
}

const Programs: React.FC<ProgramsProps> = (props) => {
  return (
    <Container padding={ContainerPaddings.BottomHalf}>
      <Heading2>{props.title}</Heading2>
      <Underline position={UnderlinePositionEnum.center} color="blueAccent" />
      <Text1 className="text-center desktop:mx-auto max-w-[976px] mt-8">
        {props.subtitle}
      </Text1>
      <ProgramsCard data={props.Program} />
    </Container>
  );
};

export default Programs;
