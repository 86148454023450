interface PreviousImageButtonProps {
  changeGalleryImage: (id: number) => void;
  activeImageId: number;
}

const PreviousImageButton: React.FunctionComponent<PreviousImageButtonProps> = (
  props
) => {
  return (
    <div
      className="absolute left-0 w-2/5 h-full z-[250]"
      onClick={() => {
        props.changeGalleryImage(props.activeImageId - 1);
      }}
    />
  );
};

export default PreviousImageButton;
