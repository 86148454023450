import { useIsPhone } from "../../common/hooks/useMediaQuery";
import Heading2 from "../../common/text/Heading2";
import Text1 from "../../common/text/Text1";
import Underline, { UnderlinePositionEnum } from "../../common/Underline";
import Container, { ContainerPaddings } from "../../layout/Container";
import GalleryImage from "./GalleryImage";
import { useGalleryPreview } from "./useGalleryPreview";

export interface GalleryImage {
  url: string;
  index: number;
  openGallery: (index: number) => void;
}

export interface GalleryProps {
  __component: string;
  title: string;
  subtitle: string;
  gallery: GalleryImage[];
}

const Gallery: React.FunctionComponent<GalleryProps> = (props) => {
  const [GalleryPreviewPortal, openPreview] = useGalleryPreview(props.gallery);

  const strapiUrl = process.env.NEXT_PUBLIC_STRAPI_URL || "";

  const isPhone = useIsPhone();

  const limit = isPhone ? 4 : 5;

  return (
    <Container
      padding={ContainerPaddings.TopHalf}
      className="flex flex-col items-center mx-auto"
    >
      <Heading2>{props.title}</Heading2>
      <Underline position={UnderlinePositionEnum.center} color="blueAccent" />
      <Text1 className="px-4 mt-8 text-center desktop:mx-auto">
        {props.subtitle}
      </Text1>
      <div className="grid w-full phone:grid-cols-2 desktop:grid-cols-5 gap-[3vw] desktop:gap-[0px] mt-8 desktop:mt-16">
        {props.gallery.slice(0, limit).map((image, index: number) => {
          return (
            <GalleryImage
              key={index}
              url={strapiUrl + image.url}
              mediumUrl={strapiUrl + image.url}
              index={index}
              openGallery={(id) => openPreview(id)}
            />
          );
        })}
      </div>
      <GalleryPreviewPortal />
    </Container>
  );
};

export default Gallery;
