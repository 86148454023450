import { AppState } from "../../../store";
import SessionStatus from "./session/SessionStatus";

export const userTokenSelector = (state: AppState): string | null => {
  return state.customerClient.sessionState.userToken;
};

export const sessionStatusSelector = (state: AppState): SessionStatus => {
  return state.customerClient.sessionState.sessionStatus;
};
