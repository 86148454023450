import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  sessionStatusSelector,
  userTokenSelector,
} from "../../../redux/rendezvous-customer-redux/_internal/customer-slice/cutomerSelectors";
import SessionStatus from "../../../redux/rendezvous-customer-redux/_internal/customer-slice/session/SessionStatus";
import { startCustomerSession } from "../../../redux/rendezvous-customer-redux/_internal/customer-slice/session/startCustomerSession";
import { useAppSelector } from "../../../redux/store";

const useCustomerSession: () => {
  isSessionStarted: boolean;
  userToken: string | null;
  sessionStatus: SessionStatus;
} = () => {
  const dispatch = useDispatch();
  const userToken = useAppSelector(userTokenSelector);
  const sessionStatus = useAppSelector(sessionStatusSelector);
  const isSessionStarted = !!userToken;

  useEffect(() => {
    if (sessionStatus == SessionStatus.NotStarted) {
      dispatch(startCustomerSession());
    }
  }, [dispatch, sessionStatus]);

  return { isSessionStarted, userToken, sessionStatus };
};

export default useCustomerSession;
