import SectionRouter from "./SectionRouter";

interface SectionContainerProps {
  pageContent: PageCollection;
}

const SectionContainer: React.FunctionComponent<SectionContainerProps> = ({
  pageContent,
}: SectionContainerProps) => {
  return (
    <>
      {pageContent.sections.map(
        (section: AbstractComponent, index: number): JSX.Element => (
          <SectionRouter key={index} section={section} />
        )
      )}
    </>
  );
};

export interface AbstractComponent {
  __component: string;
  buttonTitle?: string | undefined;
}

export interface PageCollection {
  id: number;
  created_at: string;
  updated_at: string;
  slug: string;
  pageTitle: string;
  sections: AbstractComponent[];
}

export default SectionContainer;
