import React from "react";
import CardProgram from "./CardProgram";

export interface ProgramData {
  title: string;
  subtitle: string;
  image: { url: string };
}

export interface ProgramsCardsProps {
  data: ProgramData[];
}

const ProgramsCards: React.FC<ProgramsCardsProps> = (props) => {
  return (
    <div className="flex flex-col mt-8 desktop:flex-row desktop:mt-16">
      {props.data.map((program, i) => {
        return (
          <CardProgram
            key={i}
            title={program.title}
            subtitle={program.subtitle}
            url={program.image.url}
          />
        );
      })}
    </div>
  );
};

export default ProgramsCards;
