import classNames from "classnames";
import React, { ReactNode } from "react";

interface HeroHeadingProps {
  children: ReactNode;
  className?: string;
}

const HeroHeading: React.FC<HeroHeadingProps> = (props) => {
  const className = classNames(
    "text-5xl font-extrabold font-roboto-condensed leading-[56.25px] text-center md:text-[64px] md:leading-[75px] z-10 uppercase",
    props.className
  );
  return <h1 className={className}>{props.children}</h1>;
};

export default HeroHeading;
