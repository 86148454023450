import React from "react";
import Image from "next/image";
import heroImage from "../../../public/img/hero.png";
import HeroHeading from "../../common/text/HeroHeading";
import Text1 from "../../common/text/Text1";
import Container from "../../layout/Container";
import useTrackCtaAppointments from "../../../analytics-hooks/useTrackCtaAppointments";
import Button from "../../common/Button";

export interface HeroProps {
  __component: string;
  id: number;
  title: string;
  subtitle: string;
  buttonTitle: string;
}

const Hero: React.FC<HeroProps> = (props) => {
  const dispatchCTAAppointment = useTrackCtaAppointments();

  return (
    <Container className="h-heroHeightPhone desktop:h-heroHeightDesktop desktop:pl-[124px]">
      <Image
        src={heroImage}
        layout="fill"
        alt="Background"
        className="object-cover object-center bg-opacity-50 "
      />
      <div className="flex flex-col items-center justify-center mx-auto h-4/5 desktop:items-start  desktop:ml-[124px] heroContainer:ml-0">
        <HeroHeading className="z-10 text-white max-w-[365px] desktop:text-left">
          {props.title}
        </HeroHeading>
        <Text1 className="z-10 mt-2 text-white">{props.subtitle}</Text1>
        <Button
          className="z-10 mt-8"
          color="green"
          href="/zakazi-termin"
          onClick={() => {
            dispatchCTAAppointment();
          }}
        >
          {props.buttonTitle}
        </Button>
      </div>
    </Container>
  );
};

export default Hero;
