interface NextImageButtonProps {
  changeGalleryImage: (id: number) => void;
  activeImageId: number;
}

const NextImageButton: React.FunctionComponent<NextImageButtonProps> = (
  props
) => {
  return (
    <div
      className="absolute right-0 w-3/5 h-full z-[250]"
      onClick={() => {
        props.changeGalleryImage(props.activeImageId + 1);
      }}
    />
  );
};

export default NextImageButton;
