import classNames from "classnames";
import React from "react";

interface SliderGalleryDotsProps {
  page: number;
  totalPage: number;
}

const SliderGalleryDots: React.FC<SliderGalleryDotsProps> = (props) => {
  const bg1 = props.page == 0 ? "bg-blueAccent" : "bg-[#cfcfcf]";

  const bg2 = props.page == 1 ? "bg-blueAccent" : "bg-[#cfcfcf]";

  const bg3 =
    props.page > 1 && props.page < props.totalPage - 2
      ? "bg-blueAccent"
      : "bg-[#cfcfcf]";

  const bg4 =
    props.page == props.totalPage - 2 ? "bg-blueAccent" : "bg-[#cfcfcf]";
  const bg5 =
    props.page == props.totalPage - 1 ? "bg-blueAccent" : "bg-[#cfcfcf]";

  const dotsStyle = "rounded-full w-4 h-4 mx-2";

  const className1 = classNames(bg1, dotsStyle);
  const className2 = classNames(bg2, dotsStyle);
  const className3 = classNames(bg3, dotsStyle);
  const className4 = classNames(bg4, dotsStyle);
  const className5 = classNames(bg5, dotsStyle);

  return (
    <div className="flex justify-center items-center desktop:h-auto py-8 desktop:py-11">
      <div className={className1}></div>
      <div className={className2}></div>
      <div className={className3}></div>
      <div className={className4}></div>
      <div className={className5}></div>
    </div>
  );
};

export default SliderGalleryDots;
