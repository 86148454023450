/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import useTrackCtaAppointments from "../../../analytics-hooks/useTrackCtaAppointments";
import Button from "../../common/Button";
import Heading2 from "../../common/text/Heading2";
import Text1 from "../../common/text/Text1";
import Underline, { UnderlinePositionEnum } from "../../common/Underline";

export interface AppointmentProps {
  __component: string;
  title: string;
  subtitle: string;
  buttonText: string;
}

const Appointment: React.FC<AppointmentProps> = (props) => {
  const dispatchCTAAppointment = useTrackCtaAppointments();
  return (
    <section className="mx-4 mt-20 desktop:mt-22 ">
      <div className=" px-[5vw] desktop:px-[13vw] py-16 desktop:py-0 mx-auto max-w-container desktop:bg-white bg-greyBg flex flex-col justify-center rounded-2xl">
        <Heading2>{props.title}</Heading2>
        <Underline position={UnderlinePositionEnum.center} />
        <Text1 className="mx-auto mt-8 text-center">{props.subtitle}</Text1>
        <Button
          className="mx-auto mt-16"
          href="/zakazi-termin"
          onClick={() => {
            () => {
              dispatchCTAAppointment();
            };
          }}
        >
          {props.buttonText}
        </Button>
      </div>
    </section>
  );
};

export default Appointment;
