import React from "react";
import Heading2 from "../../common/text/Heading2";
import Text1 from "../../common/text/Text1";
import Underline, { UnderlinePositionEnum } from "../../common/Underline";
import Container from "../../layout/Container";

export interface DescriptionProps {
  __component: string;
  id: number;
  title: string;
  content: string;
  content2: string;
}

const Description: React.FC<DescriptionProps> = (props) => {
  return (
    <Container>
      <div>
        <Heading2>{props.title}</Heading2>
        <Underline position={UnderlinePositionEnum.center} color="blueAccent" />
      </div>
      <div>
        <Text1 className="px-4 mt-8 text-center desktop:mx-auto">
          {props.content}
        </Text1>

        <Text1 className="px-4 mt-8 text-center desktop:mx-auto">
          {props.content2}
        </Text1>
      </div>
    </Container>
  );
};

export default Description;
