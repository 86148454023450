/* eslint-disable @next/next/no-img-element */
import classNames from "classnames";

interface GalleryImageProps {
  url: string;
  index: number;
  mediumUrl: string;
  openGallery: (index: number) => void;
}

const GalleryImage: React.FunctionComponent<GalleryImageProps> = (props) => {
  const imgClass = classNames("object-cover w-full h-full");

  return (
    <div
      className="phone:h-[45.5vw] w-[94vw] h-[94vw] phone:w-auto relative desktop:h-desktopGallerySize desktop:w-desktopGallerySize  cursor-pointer overflow-hidden"
      onClick={() => props.openGallery(props.index)}
    >
      <img
        src={props.mediumUrl}
        alt="Gallery Image"
        className={imgClass}
        loading="lazy"
      />
    </div>
  );
};

export type { GalleryImageProps };
export default GalleryImage;
