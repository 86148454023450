import React, { useState } from "react";
import { GalleryImage } from "./Gallery";
import GalleryPreview from "./GalleryPreview";

export const useGalleryPreview: (
  gallery: GalleryImage[]
) => readonly [() => JSX.Element, (id: number) => void, () => void] = (
  gallery: GalleryImage[]
) => {
  const [openedGallery, setOpenedGallery] = useState(false);
  const [activeImageId, setActiveImageId] = useState(0);

  const openPreview = (id: number) => {
    setOpenedGallery(true);
    setActiveImageId(id);
  };

  const closePreview = () => {
    setOpenedGallery(false);
  };

  const changeGalleryImage = (id: number) => {
    if (id < 0) {
      setActiveImageId(gallery.length - 1);
    } else if (id > gallery.length - 1) {
      setActiveImageId(0);
    } else setActiveImageId(id);
  };

  const GalleryPreviewPortal = () => {
    return (
      <GalleryPreview
        toggleOpenedGallery={(openedGallery: boolean) => {
          setOpenedGallery(!openedGallery);
        }}
        openedGallery={openedGallery}
        activeImageId={activeImageId}
        changeGalleryImage={(id) => {
          changeGalleryImage(id);
        }}
        gallery={gallery}
      />
    );
  };

  return [GalleryPreviewPortal, openPreview, closePreview] as const;
};
