/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import React from "react";

export interface CardProgramProps {
  title: string;
  subtitle: string;
  url: string;
}

const CardProgram: React.FC<CardProgramProps> = (props) => {
  const strapiUrl = process.env.NEXT_PUBLIC_STRAPI_URL || "";

  return (
    <div className="my-5 bg-greyBg drop-shadow-cardShadow  h-[443px] px-6 pt-6 pb-8 flex flex-col items-center rounded-[20px] max-w-[336px] mx-auto">
      <img
        src={strapiUrl + props.url}
        alt="program tennis"
        className="rounded-md"
      />
      <p className="mt-6 text-2xl leading-[28px] font-bold font-roboto-condensed">
        {props.title}
      </p>
      <p className="mt-2 text-base font-light font-roboto w-[224px] text-center">
        {props.subtitle}
      </p>
      <Link href="/skola-tenisa" passHref>
        <a className="border w-[162px] min-h-[44px] mt-12 border-black cursor-pointer font-roboto-condensed font-bold inline-flex items-center hover:bg-blueAccent hover:text-white hover:border-0">
          <span className="mx-auto">saznaj više</span>
        </a>
      </Link>
    </div>
  );
};

export default CardProgram;
