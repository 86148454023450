import { GetStaticProps, NextPage } from "next";
import Head from "next/head";
import useCustomerSession from "../components/common/hooks/useCustomerSession";
import SectionContainer, {
  PageCollection,
} from "../components/common/SectionContainer";
import allStrapiServices from "../services/strapiService";

interface HomePageProps {
  data: PageCollection;
}

const Home: NextPage<HomePageProps> = ({ data }) => {
  useCustomerSession();

  return (
    <>
      <Head>
        <title></title>
        <meta name="description" content="content" />
        <meta name="keywords" content="Teniski klub Maksim" />
        <title>{data.pageTitle}</title>
      </Head>
      <SectionContainer pageContent={data} />
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const res = await allStrapiServices.pageData();
  const data = res.data;
  return {
    props: {
      data,
    },
  };
};

export default Home;
