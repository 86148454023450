import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { useIsPhone } from "../../common/hooks/useMediaQuery";
import Container, { ContainerPaddings } from "../../layout/Container";
import SliderGalleryDots from "./SliderGalleryDots";

export interface SliderGalleryProps {
  __component: string;
  gallery: { url: string }[];
}

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 1,
    };
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 1,
    };
  },
};

const swipeConfidenceThreshold = 10000;

const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const StrapiUrl = process.env.NEXT_PUBLIC_STRAPI_URL || "";

const SliderGallery: React.FC<SliderGalleryProps> = (props) => {
  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = (newDirection: number) => {
    if (
      page + newDirection > -1 &&
      page + newDirection < props.gallery.length
    ) {
      setPage([page + newDirection, newDirection]);
    }
  };

  const isPhone = useIsPhone();

  return (
    <Container padding={ContainerPaddings.NoPadding}>
      <div className="pt-6 md:pt-16 bg-greyBg  desktop:w-full desktop:mx-auto desktop:h-full rounded-[20px]">
        <div className="desktop:flex desktop:h-full">
          {!isPhone && (
            <div className="flex grow h-[500px] justify-start ml-16 items-center">
              <div
                className="flex items-center justify-center w-16 h-16 text-4xl bg-white rounded-full cursor-pointer"
                onClick={() => {
                  paginate(-1);
                }}
              >
                <MdArrowBackIosNew />
              </div>
            </div>
          )}
          <AnimatePresence initial={false} custom={direction}>
            <div className="px-6 desktop:px-0 overflow-hidden desktop:w-[500px] desktop:h-[500px] desktop:static desktop:mx-auto bg-greyBg">
              <motion.img
                className="mx-auto  bg-center  object-cover desktop:w-[500px] desktop:h-[500px] "
                key={page}
                src={StrapiUrl + props.gallery[page].url}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragEnd={(e, { offset, velocity }) => {
                  const swipe = swipePower(offset.x, velocity.x);

                  if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                  } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                  }
                }}
                onClick={() => {
                  paginate(1);
                }}
                style={{
                  boxShadow: "inset 10px 10px 10px 10px rgb(0 0 0 / 0.3)",
                }}
              />
            </div>
          </AnimatePresence>
          {!isPhone && (
            <div className="flex grow h-[500px] justify-end mr-16 items-center">
              <div
                className="flex items-center justify-center w-16 h-16 text-4xl bg-white rounded-full cursor-pointer"
                onClick={() => {
                  paginate(1);
                }}
              >
                <MdArrowForwardIos />
              </div>
            </div>
          )}
        </div>

        <SliderGalleryDots page={page} totalPage={props.gallery.length} />
      </div>
    </Container>
  );
};
export default SliderGallery;
